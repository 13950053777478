import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import LinearProgressBar from "@mui/material/LinearProgress";

export default function LinearProgress({ toggle }) {
  const [showLoader, setShowLoader] = React.useState(true);

  React.useEffect(() => {
    setShowLoader(toggle);
  }, [toggle]);

  return showLoader ? (
    <Box sx={{ width: "100%" }}>
      <LinearProgressBar />
    </Box>
  ) : null;
}

LinearProgress.defaultProps = {
  toggle: true,
};

LinearProgress.propTypes = {
  toggle: PropTypes.bool,
};
