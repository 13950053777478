/* eslint-disable no-useless-catch */
import { createAsyncThunk } from "@reduxjs/toolkit";
import requestApi from "config/apiHandler";
import STATUSES from "data/constants/STATUSES";
import cookiesManipulator from "services/utils/cookiesManipulator";

const uri = "auths";

export const getIdentity = createAsyncThunk(
  `${uri}/getIdentity`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}/identities`, "POST", requestBody);
      // const response = {
      //   data: { identity: "324243324324234", user: { credits: 3 } },
      //   code: "success",
      //   message: "error while fetching the data",
      // };
      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);

      throw err;
    }
  }
);
export const getProviders = createAsyncThunk(
  `${uri}/getProviders`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}/providers`, "GET");
      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);

export const requestAuth = createAsyncThunk(
  `${uri}/requestAuth`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}/requests`, "POST", requestBody);
      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);
export const verifyLogin = createAsyncThunk(
  `${uri}/verifyLogin`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status, selectedProvider } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}/verify`, "POST", requestBody);
      if (response.code === "success") {
        await cookiesManipulator.setAuth(response.data);
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);

export const logout = createAsyncThunk(
  `${uri}/logout`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}/logout`, "PUT");
      if (response.code === "success") {
        await cookiesManipulator.removeAuth();
        return response.message;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);
