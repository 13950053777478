/* eslint-disable import/no-named-as-default */
import { configureStore } from "@reduxjs/toolkit";
import authSlice from "store/slices/authSlice";
// import projectSlice from "store/slices/projectSlice";
// import templateSlice from "store/slices/templateSlice";
import fileSlice from "store/slices/fileSlice";
// import contactSlice from "store/slices/contactSlice";
// import reportSlice from "store/slices/reportSlice";

const store = configureStore({
  reducer: {
    auths: authSlice,
    files: fileSlice,
    // projects: projectSlice,
    // templates: templateSlice,
    // contact: contactSlice,
    // report: reportSlice,
  },
});
export default store;
