/* eslint-disable no-useless-catch */
import { createAsyncThunk } from "@reduxjs/toolkit";
import requestApi from "config/apiHandler";
import STATUSES from "data/constants/STATUSES";
import uploadToS3 from "services/Export/uploadToS3";
import { v4 as uuidv4 } from "uuid";
import { Buffer } from "buffer";
import cookiesManipulator from "services/utils/cookiesManipulator";

const uri = "files";
export const getFiles = createAsyncThunk(
  `${uri}/getFiles`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      // const response = await requestApi(`${uri}?user_id=${requestBody}`, "GET");
      const response = await requestApi(`${uri}?project_id=1`, "GET", requestBody);

      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);
export const postFiles = createAsyncThunk(
  `${uri}/postFiles`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];

    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    const fileContent = {
      Body: await Buffer.from(requestBody.buffer.replace(/^data:image\/\w+;base64,/, ""), "base64"),
      Key: `docs_scan/${uuidv4().replace(/-/g, "")}_${requestBody.fileName}`,
    };

    try {
      const uploadedFile = await uploadToS3(fileContent);

      const body = {
        identity: requestBody.identity,
        file_url: uploadedFile.Location,
        thumbnail_url: uploadedFile.Location,
        project_id: 1,
        uuid: `${uuidv4().replace(/-/g, "")}_${requestBody.fileName}`,
      };

      // const Location =
      //   "https://xane-user-data.s3.amazonaws.com/docs_scan/a0ace4509c1b47ff8059f91f3a530f43_0b187e552ef542c7a5a3de17ed603923.jpg";

      const response = await requestApi(`${uri}`, "POST", body);
      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);

export const deleteFile = createAsyncThunk(
  `${uri}/deleteFile`,
  async (requestBody, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, status } = getState()[uri];
    if (status !== STATUSES.LOADING || requestId !== currentRequestId) {
      return null;
    }

    try {
      const response = await requestApi(`${uri}/${requestBody}`, "PUT", {
        active: 0,
      });

      if (response.code === "success") {
        return response.data;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(response.message);
    } catch (err) {
      // alert(err);
      throw err;
    }
  }
);
