/* eslint-disable camelcase */
import { Verified } from "@mui/icons-material";
import { createSlice } from "@reduxjs/toolkit";
import STATUSES from "data/constants/STATUSES";
import cookies from "data/formats/cookies";
import cookiesManipulator from "services/utils/cookiesManipulator";
import { readFromLocalStorage, writeToLocalStorage } from "services/utils/localStorage";

import { verifyLogin, requestAuth, getIdentity, logout, getProviders } from "store/actions/auths";

const initialState = {
  currentRequestId: undefined,
  identity: cookiesManipulator.getAuth().identity,
  phone_num: readFromLocalStorage("phone_num"),
  reference: null,
  isVerified: !!cookiesManipulator.getAuth().token,
  status: STATUSES.IDLE,
  requestState: null,
  errorMessage: null,
  providers: [],
  selectedProvider: null,
  userData: cookiesManipulator.getAuth().user,
  session: { token: null, expiry: null },
};

export const authSlice = createSlice({
  name: "auths",
  initialState,
  reducers: {
    clearReference(state) {
      state.reference = null;
    },
    clearAuthErrorState(state) {
      state.status = STATUSES.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // ################# Getting providers ####################
      .addCase(getProviders.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getProviders.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { providers } = action.payload;
          state.status = STATUSES.IDLE;
          state.providers = providers;
          state.selectedProvider = providers.some((provider) => provider.code === "credentials")
            ? providers[0].id
            : null;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getProviders.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      // ################# Authentication request ####################
      .addCase(requestAuth.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(requestAuth.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { authentication_request } = action.payload;

          state.status = STATUSES.IDLE;
          state.phone_num = authentication_request.phone_num;
          writeToLocalStorage("phone_num", authentication_request.phone_num);
          state.reference = authentication_request.reference;
          state.expiry = authentication_request.expiry;
          state.currentRequestId = undefined;
        }
      })
      .addCase(requestAuth.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      // ################# Auth verification ####################
      .addCase(verifyLogin.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(verifyLogin.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { session, user } = action.payload;

          state.status = STATUSES.IDLE;
          state.session.token = session.token;
          state.session.expiry = session.expiry;
          state.userData = user;
          state.isVerified = true;
          state.currentRequestId = undefined;
        }
      })
      .addCase(verifyLogin.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here. action.payload.errorMessage
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.isVerified = false;
          state.status = STATUSES.ERROR;
        }
      })
      // ################# Logging out ####################
      .addCase(logout.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(logout.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          state.status = STATUSES.IDLE;
          state.isVerified = false;
          state.userData = { credits: 3 };

          state.currentRequestId = undefined;
        }
      })
      .addCase(logout.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      })
      // ################# Get Identification ####################
      .addCase(getIdentity.pending, (state, action) => {
        if (state.status === STATUSES.IDLE) {
          state.status = STATUSES.LOADING;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getIdentity.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          const { user } = action.payload;
          state.identity = user.identity;
          const { identity } = user;
          cookiesManipulator.setAuth({ ...cookies, identity, user });
          state.status = STATUSES.IDLE;
          state.currentRequestId = undefined;
        }
      })
      .addCase(getIdentity.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === STATUSES.LOADING && state.currentRequestId === requestId) {
          state.errorMessage = action.payload ? action.payload : action.error.message;
          state.currentRequestId = undefined;
          state.status = STATUSES.ERROR;
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const { clearReference, clearAuthErrorState } = authSlice.actions;

export default authSlice.reducer;
